const ID_TOKEN_KEY = "id_token";
const APP_USER = 'user';
const affiliate_id = 'affiliate_id';
const ACCESS_TOKEN = 'access_token';

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const getUser = () => {
  return window.localStorage.getItem(APP_USER);
};

export const saveToken = (token,user) => {
  //window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(APP_USER, user.first_name);
  window.localStorage.setItem(affiliate_id, user.id);
  window.localStorage.setItem(ACCESS_TOKEN, user.access_token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(APP_USER);
};

export default { getToken, saveToken, destroyToken };