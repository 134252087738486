import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://127.0.0.1:8008/api/auth";
    // Vue.axios.defaults.baseURL = "http://13.212.193.16/api/auth";
    // Vue.axios.defaults.baseURL = "https://api.cpx.ae/api/auth";
    // Vue.axios.defaults.baseURL = "http://dev.api.cpx.ae/api/auth";
    Vue.axios.defaults.timeout = 60000;
  }, 

  /**
   * Set the default HTTP request headers
   */
  // setHeader() {
  //   Vue.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Token ${JwtService.getToken()}`;
  // },
  setHeader() {
    // Vue.axios.defaults.baseURL = "https://dev.api.cpx.ae/api/auth";
    Vue.axios.defaults.baseURL = "https://dados.cpx.ae/api";
    Vue.axios.defaults.headers.common[
      "token"
    ] = `${JwtService.getToken()}`;
  },
  /**
   * Set the default HTTP request headers
   */
  // setHeader() {
  //   Vue.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Token ${JwtService.getToken()}`;
  // },
  setHeader1() {
    // Vue.axios.defaults.baseURL = "https://dev.api.cpx.ae/api/auth";
    Vue.axios.defaults.baseURL = "https://dados.cpx.ae/api";
    Vue.axios.defaults.headers.common[
      "token"
    ] = `${JwtService.getToken()}`;
  },

  query(resource, params) {
    this.setHeader();
    return Vue.axios.get(resource, params).catch(error => {
      if(error.response.status == 400){
        window.localStorage.clear();
        window.location.href = "/"
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    this.setHeader();
    return Vue.axios.get(`${resource}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post2(resource, params,abortController) {
    this.setHeader1();
    return Vue.axios.post(`${resource}`, params )
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(resource).catch(error => {
      if(error.response.status == 400){
        localStorage.clear();
        localStorage.removeItem("affiliate_id");
        Swal.fire({
          imageUrl: "media/images/logout-emoji.png",
          imageWidth: 165,
          imageHeight: 123,
          title: "Your Session is expired.",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },  

  /**
   * Send the INDIGO GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
   getIndigo(resource) {
     this.setHeader();
    var url = 'http://indigo.cpx.ae/api/';
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    return Vue.axios.get(`${url+resource}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },
};

export default ApiService;