import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { VuejsDatatableFactory } from 'vuejs-datatable';
import globalMixin from "@/core/mixins";
Vue.mixin(globalMixin);

Vue.use( VuejsDatatableFactory );

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import 'sweetalert2/dist/sweetalert2.css';
// import GAuth from 'vue-google-oauth2'

// const gauthOption = {
//   clientId: '887797868713-umk76qetd7gkuhk15k8thitd4liqrr5t.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'consent'
// }
// Vue.use(GAuth, gauthOption)
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
    window.scrollTo(0, 0);
});

// Vue.component("select_all", () =>
//   import(/* webpackChunkName: "select_all" */ "./view/components/select-all.vue"
// )
// );
new Vue({
  router,
  store,
  i18n,
  vuetify,
  VuejsDatatableFactory,
  render: h => h(App)
}).$mount("#app");


