import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const GOOGLE_LOGIN = "googleLogin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
// Added By Farheen
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "user";
export const SET_ERROR = "setError";
export const SET_ERROR_MSG = "setErrorMsg";

// Added By Farheen
export const SET_ERROR_FORGOT_MSG = "setErrorForgotMsg";
export const SET_ERROR_RESET_MSG = "setErrorResetMsg";
export const SET_SUCCESS = "setSuccess";
export const SET_SUCCESS_MSG = "setSuccessMsg";

const state = {
  loginError:null,
  forgotPasswordError:null,
  errors: null,
  successs :null,
  forgotPasswordSuccess:null,
  resetPasswordSuccess:null,
  resetPasswordError:null,
  successReset : null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      // ApiService.post("/login", credentials)
        ApiService.post("/confirm_otp", credentials)
          .then(({ data }) => {
            // set user
            if(data.result == false){              
              context.commit(SET_ERROR, data.error.message);
              context.commit(SET_ERROR_MSG, data.error.message);
              this.state.loginError = data.error.message
              return true;
            }
            context.commit(SET_USER,data.data);
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            console.log(response,"res");
            context.commit(SET_ERROR, response.data.error.message);
            context.commit(SET_ERROR_MSG, response.data.error.message);
            this.state.loginError = response.data.error.message
          }).catch(error=>{            
            console.log(error,"err");
            context.commit(SET_ERROR, 'Please check your network connection and try again.');
            context.commit(SET_ERROR_MSG, 'Please check your network connection and try again.');
            this.state.loginError = 'Please check your network connection and try again.'
          })
    });
  },
  
  [GOOGLE_LOGIN](context, credentials) {
    return new Promise(resolve => {
      // ApiService.post("/login", credentials)
        ApiService.post(`/google_verification`,{code:credentials.access_token,email:credentials.email})
          .then(({ data }) => {
            // set user
            if(data.result == false){              
              context.commit(SET_ERROR, data.error.message);
              context.commit(SET_ERROR_MSG, data.error.message);
              this.state.loginError = data.error.message
              return true;
            }
            context.commit(SET_USER,data.data);
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            console.log(response,"res");
            context.commit(SET_ERROR, response.data.error.message);
            context.commit(SET_ERROR_MSG, response.data.error.message);
            this.state.loginError = response.data.error.message
          }).catch(error=>{            
            console.log(error,"err");
            context.commit(SET_ERROR, 'Please check your network connection and try again.');
            context.commit(SET_ERROR_MSG, 'Please check your network connection and try again.');
            this.state.loginError = 'Please check your network connection and try again.'
          })
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.post("/verifyotp")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [FORGOT_PASSWORD](context, email) {
    return new Promise(resolve => {
      // ApiService.post("/login", email)
      ApiService.post("/forgot-password", email)
        .then(({ data }) => {
          context.commit(SET_SUCCESS,data.message); 
          context.commit(SET_SUCCESS_MSG, data.message);
          this.state.forgotPasswordSuccess = data.message;
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          context.commit(SET_ERROR_FORGOT_MSG, response.data.message);
          this.state.forgotPasswordError = response.data.message
        });
    });
  },
  [RESET_PASSWORD](context, details) {
    return new Promise(resolve => {
      // ApiService.post("/login", details)
      ApiService.post("/reset-password", details)
        .then(({ data }) => {
          context.commit(SET_SUCCESS,data.message); 
          context.commit(SET_SUCCESS_MSG, data.message);
          this.state.resetPasswordSuccess = data.message;
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          context.commit(SET_ERROR_RESET_MSG, response.data.message);
          this.state.resetPasswordError = response.data.message
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ERROR_MSG](state, error) {
    state.loginError = error;
  },
  [SET_ERROR_FORGOT_MSG](state, error) {
    state.forgotPasswordError = error;
  },
  [SET_ERROR_RESET_MSG](state, error) {
    state.resetPasswordError = error;
  },
  [SET_SUCCESS](state, success) {
    state.successs = success;
  },
  [SET_SUCCESS_MSG](state, success) {
    state.forgotPasswordSuccess = success;
    state.resetPasswordSuccess = success;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // console.log('set authication',state.user);
    JwtService.saveToken(state.user.token,user);
  },
  [SET_USER](state,payload){
    state.user = payload;
    // console.log("Set User Mutation", state.usser)
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
